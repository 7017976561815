import { render, staticRenderFns } from "./CourseReviewsForm.vue?vue&type=template&id=a7eceebe&scoped=true&lang=pug&"
import script from "./CourseReviewsForm.vue?vue&type=script&lang=ts&"
export * from "./CourseReviewsForm.vue?vue&type=script&lang=ts&"
import style0 from "./CourseReviewsForm.vue?vue&type=style&index=0&id=a7eceebe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a7eceebe",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
installComponents(component, {VBtn,VCardText})
