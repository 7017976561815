











































import { Component, Prop } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

// components
import ButtonTextIcon from '@/components/_uikit/buttons/ButtonTextIcon.vue'
import CardHint from '@/components/_uikit/CardHint.vue'
import ReviewCard from '@/components/cards/ReviewCard.vue'
import ReviewChoiceModal from '@/components/modals/courses/ReviewChoiceModal.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import { CourseLargeResource, CourseType, ReviewResource } from '@/store/types'
import ReviewSlider from '@/components/ReviewSlider.vue'
import ManagerCoursesModule from '@/store/modules/manager/courses'

@Component({
  components: {
    ButtonTextIcon,
    CardHint,
    ReviewCard,
    ReviewChoiceModal,
    ReviewSlider,
    TextInput,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class CourseReviewsForm extends NotifyMixin {
  @Prop({ required: true })
  private course!: CourseLargeResource

  private get isSpecial() {
    return this.course ? this.course.type.value === CourseType.SPECIAL : this.form.type === CourseType.SPECIAL
  }

  private form: any = {
    link: '',
  }

  private sliderOptions: any = {
    slidesPerView: 1,
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      600: {
        slidesPerView: 2,
        spaceBetween: 24,
      },
    },
  }

  private reviews: ReviewResource[] = []

  private showReviewChoiceModal = false
  private isLoaded = false

  private mounted() {
    ManagerCoursesModule.fetchReviewsCourse(this.course.id)
      .then(response => {
        this.form.link = response.reviewLink || ''
        this.reviews = response.reviews
      })
      .catch(this.notifyError)
      .finally(() => this.isLoaded = true)
  }

  private addReview() {
    this.showReviewChoiceModal = true
  }

  private handleSelect(reviews: ReviewResource[]) {
    this.reviews = reviews
    setTimeout(() => {
      this.$bus.$emit('swiperUpdate')
    })
  }

  private removeReview(reviewID: number) {
    this.reviews = this.reviews.filter(review => review.id !== reviewID)
    setTimeout(() => {
      this.$bus.$emit('swiperUpdate')
    })
  }

  private handleSubmit() {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result)
          this.saveReviewsCourse()
        else
          this.notifyError('Проверьте введенные данные')
      })
  }

  private saveReviewsCourse() {
    ManagerCoursesModule.saveReviewsCourse({
      body: {
        reviewIds: this.reviews.map(review => review.id as number),
        reviewLink: this.form.link,
      },
      courseID: this.course.id,
    })
      .then(() => this.notifySuccess('Отзывы успешно добавлены к курсу'))
      .catch(this.notifyError)
  }
}
