







import { Component, Prop, Vue } from 'vue-property-decorator'

// components
import CourseReviewsForm from '@/components/forms/course/CourseReviewsForm.vue'
// store
import { CourseLargeResource } from '@/store/types'

@Component({
  components: {
    CourseReviewsForm,
  },
})
export default class Reviews extends Vue {
  @Prop({ required: true })
  private course!: CourseLargeResource
}
