































































































import { Component, Prop, Watch } from 'vue-property-decorator'
import { Bind, Debounce } from 'lodash-decorators'

// components
import ReviewModal from '@/components/modals/ReviewModal.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import TableFooter from '@/components/_uikit/TableFooter.vue'
import Tag from '@/components/_uikit/Tag.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import DictionaryModule from '@/store/modules/dictionary'
import ManagerCoursesModule from '@/store/modules/manager/courses'
import { GET_DEFAULT_PAGINATOR, GET_DEFAULT_TABLE_FILTER } from '@/store'
import { IManagerReviewsCourseFilterType, ITableOptions, ManagerReviewsGetParams, ReviewPaginator, ReviewResource } from '@/store/types'
// utils
import { tableFooterOptions } from '@/utils/constants'
import { tableOptionsToParams } from '@/utils/functions'

@Component({
  components: {
    ReviewModal,
    Select,
    TableFooter,
    Tag,
    TextInput,
  },
})
export default class ReviewChoiceModal extends NotifyMixin {
  @Prop({ required: true })
  private visible!: boolean

  @Prop({ required: true })
  private selected!: ReviewResource[]

  private get subjects () {
    return DictionaryModule.subjects
  }

  private get headers () {
    return [
      { sortable: false, cellClass: 'cell-icon cell-icon_first', text: '', value: 'data-table-select' },
      { text: 'Тип', value: 'type' },
      { text: 'Автор отзыва', value: 'author' },
      { text: 'Балл ЕГЭ', value: 'exam_score' },
      { sortable: false, text: 'Предмет', value: 'subject' },
    ]
  }

  private filter: ManagerReviewsGetParams & ITableOptions = {
    ...GET_DEFAULT_TABLE_FILTER(),
    itemsPerPage: 10,
  }

  private footerOptions = tableFooterOptions

  private scrollOptions = {
    scrollPanel: {
      scrollingX: false,
    },
  }

  private selectReview: ReviewResource | null = null

  private types = {
    text: 'Текст',
    video: 'Видео',
  }

  private showReviewModal = false

  private reviews: ReviewPaginator = {
    ...GET_DEFAULT_PAGINATOR(),
  }

  private innerSelected: ReviewResource[] = []

  private mounted() {
    this.initialSelect()
  }

  private initialSelect() {
    this.innerSelected = this.selected
  }

  private handleFilter(field: IManagerReviewsCourseFilterType, value: never) {
    this.filter = {
      ...this.filter,
      [field]: value,
      page: 1,
    }
  }

  private handleClose () {
    this.$emit('update:visible', false)
    this.$emit('close', false)
  }

  private handleSelect () {
    this.$emit('select', this.innerSelected)
    this.innerSelected = []
    this.handleClose()
  }

  private handleRowClick(item: ReviewResource) {
    this.selectReview = item
    this.showReviewModal = true
  }

  private handleCloseModal() {
    this.selectReview = null
    this.showReviewModal = false
  }

  @Bind
  @Debounce(500)
  private searchReviews () {
    ManagerCoursesModule.fetchReviews(tableOptionsToParams(this.filter))
      .then(response => {
        this.reviews = response
      })
      .catch(this.notifyError)
  }

  @Watch('filter', { deep: true })
  private watchFilter() {
    this.searchReviews()
  }

  @Watch('visible')
  private watchVisible(value: boolean) {
    if (value)
      this.initialSelect()
  }
}
